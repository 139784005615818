/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

.stack-container {
    display: flex;
    align-items: start;
}
.half-screen-stack {
    width: 50%;
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

* {
    --landover-blue: #003f86;
    --landover-blue-light: hsl(212, 100%, 40%);
}

.main-header,
.user-header,
.sidebar {
    .logo,
    .navbar {
        background-color: var(--landover-blue) !important;
    }
    background-color: var(--landover-blue) !important;
}

.sidebar .nav-item {
    background-color: var(--landover-blue) !important;
}

.treeview-menu .nav-item {
    background-color: var(--landover-blue-light) !important;
}

.elc-overflowing-column {
    > div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.elc-width-300 {
    > div {
        width: 300px;
    }
}
.elc-width-10em {
    > div {
        width: 10em;
    }
}

//adding ellipsis to title of calendar events
.fc-event-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100%);
    display: block;
    overflow: hidden;
}

.fc-button
{
    position: relative;
    z-index: 21;
}

.mr-5 {
    margin-right: 5px;
}
#counterflow-cleaning-additional-images #removePhotoButton{
    display: none;
}


.cursor-auto {
    cursor: auto;
}

.text-align-left {
    text-align: left;
}


.width-55p {
    width: 55%;
}

.width-10p {
    width: 10%;
}
.width-15p {
    width: 15%;
}
